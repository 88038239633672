const GTrackColorList = []
const GColor = function (r, g, b) {
  r = typeof r === 'undefined' ? 0 : r
  g = typeof g === 'undefined' ? 0 : g
  b = typeof b === 'undefined' ? 0 : b
  return { r: r, g: g, b: b }
}

const createColorRange = function (c1, c2, n) {
  let tmpColor
  for (let i = 0; i < n; i++) {
    tmpColor = new GColor()
    tmpColor.r = c1.r + (i * (c2.r - c1.r)) / n
    tmpColor.g = c1.g + (i * (c2.g - c1.g)) / n
    tmpColor.b = c1.b + (i * (c2.b - c1.b)) / n
    GTrackColorList.push(tmpColor)
  }
  console.log(GTrackColorList)
}

// 设置默认值: 红---> 绿
createColorRange({ r: 255, g: 0, b: 0 }, { r: 0, g: 255, b: 0 }, 50)

// 获取轨迹颜色
export function getColor(val) {
  val = val - 1
  if (val > 49) {
    val = 49
  }
  if (val < 0 || val >= GTrackColorList.length) {
    return ''
  }
  const color = GTrackColorList[val]
  return `rgb(${color.r},${color.g},${color.b})`
}
// 获取轨迹颜色列表
export function getTrackColorList() {
  return GTrackColorList
}
